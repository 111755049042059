

$('document').ready(function () {

	$(".js-header").headroom();

	$('.js-article-tile-header').responsiveEqualHeightGrid();
	$('.js-article-tile-description').responsiveEqualHeightGrid();
	$('.js-tile-description').responsiveEqualHeightGrid();
	$('.js-cta-block').responsiveEqualHeightGrid();
	

	$('.js-burger').click(function(){
		$('.js-mobile').toggleClass('is-open');
		$(this).toggleClass('i--mob-menu-burger');
		$(this).toggleClass('i--mob-menu-close');

		$('.js-header').toggleClass('is-fixed');

	});

	$('.js-open-modal').click(function(){
		$('.js-modal').addClass('is-visible');
		$('.js-overlay').addClass('is-visible');
	});

	$('.js-overlay').click(function(){
		$('.js-modal').removeClass('is-visible');
		$(this).removeClass('is-visible');
	});

	$('.js-modal-close').click(function(){
		$('.js-modal').removeClass('is-visible');
		$('.js-overlay').removeClass('is-visible');
	});



	/*$(".js-header").headroom();

	$('.js-burger').click(function(){
		$('.js-menu').toggleClass('is-open');
		$('.js-header').toggleClass('headroom--menu-open');

		var txt = $(".js-menu").hasClass('is-open') ? 'Close' : 'Explore';

		$('.js-burger .i').fadeOut(200, function(){
			$(this).toggleClass('i--menu');
			$(this).toggleClass('i--menu-close').fadeIn(200);
		});

		$('.js-burger .icon__text').fadeOut(200,function(){
			$(this).text(txt).fadeIn(200);
		});
	});*/

});


